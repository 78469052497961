import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/gitlab-runner/builds/_fryZbSQ/0/adnan.begoli/is-microfinance/packages/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "upravljanje-sadržajem"
    }}>{`Upravljanje sadržajem`}</h1>
    <p>{`Tekst, dokumenti, kontakt informacije, slike, i ostali sadržaj se podešava
kroz CMS tako što se sačuva željena izmjena,
koja će se zatim unutar par minuta prikazati na stranici.`}</p>
    <p>{`Šifrarničke informacije (kreditni proizvodi, organizacije, vrsta isplate, itd.)
se mijenjaju kroz direktnu integraciju iMikro sistema i web zahtjeva. Potrebno je naglasiti da se izmjene `}<strong parentName="p">{`automatski odraze na admin aplikaciju`}</strong>{`, ali da
`}<strong parentName="p">{`web zahtjev stranica prema klijentu zahtjeva dodatnu akciju`}</strong>{`
kroz CMS da bi se ažurirali podaci.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Trenutno nije podržan preview izmjena, pa je preporučeno da se sve izmjene
isprate na produkciji da bi osigurali da se dobro uklapaju na stranici web zahtjeva.`}</p>
    </blockquote>
    <h2 {...{
      "id": "strapi-cms"
    }}>{`Strapi CMS`}</h2>
    <p>{`Kroz Strapi mijenjate slike i tekstualni sadržaj stranice, i podešavate
opcije poput da li da se prikaže account manager na zahtjev primljen stranici.`}</p>
    <p>{`Na lijevom meniju se nalaze upravljive stranice. Klikom na stranicu možete mijenjati sadržaj.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Korak 1`}</strong>{`. Zaglavlje forme, i legalni dokumenti.`}</li>
      <li parentName="ul"><strong parentName="li">{`Korak 2`}</strong>{`. Zaglavlje forme.`}</li>
      <li parentName="ul"><strong parentName="li">{`Korak 3`}</strong>{`. Zaglavlje forme i labele za postojeće zaduženje.`}</li>
      <li parentName="ul"><strong parentName="li">{`Korak 4`}</strong>{`. Zaglavlje forme i tekst, labele za pojedine dokumente, i CRK saglasnost opis i dokument.`}</li>
      <li parentName="ul"><strong parentName="li">{`Zahtjev primljen`}</strong>{`. Tekstualni sadržaj, slika i informacije o account manageru, i da li da se ikako prikazuje (atribut `}<em parentName="li">{`Vidljiv`}</em>{`). Kontakt informacije u lijevoj kutiji i koraci u desnoj.`}</li>
      <li parentName="ul"><strong parentName="li">{`Kontakt`}</strong>{`. Kontakt kanali tj. lista koja se prikaže kada se klikne na dugme kontakt i sadržaj modala koji se pojavi nakon što je korisnik neaktivan više od 30 sekundi.`}</li>
      <li parentName="ul"><strong parentName="li">{`Naslovna`}</strong>{`. Naslovna slika.`}</li>
    </ul>
    <h3 {...{
      "id": "prijava-korisnika"
    }}>{`Prijava korisnika`}</h3>
    <p>{`Slijedite uputstva za prijavu i registraciju korisnika koja ste dobili putem
maila ili kontaktirajte admina.`}</p>
    <h3 {...{
      "id": "izmjena-tekstualnog-sadržaja"
    }}>{`Izmjena tekstualnog sadržaja`}</h3>
    <p>{`Sadržaj mijenjate tako što odaberete odgovarajuću stranicu. Izmjenite vrijednost polja i
kliknete dugme Save.`}</p>
    <p>{`Možete napraviti više izmjena odjednom. Klik na Save dugme okida ponovno podizanje
web zahtjev stranice, koje traje 2-3 minute.`}</p>
    <p><img alt="alt text" src={require("./assets/strapi-1.jpeg")} /></p>
    <h3 {...{
      "id": "ažuriranje-slika-i-dokumenata"
    }}>{`Ažuriranje slika i dokumenata`}</h3>
    <p>{`Novi dokument se postavlja klikom na plus ikonicu. Možete postaviti dokumente poput:`}</p>
    <ul>
      <li parentName="ul">{`CRK saglasnosti`}</li>
      <li parentName="ul">{`Uslove poslovanja i pravila privatnosti`}</li>
      <li parentName="ul">{`Standardni informacioni list`}</li>
      <li parentName="ul">{`Naslovu sliku`}</li>
    </ul>
    <p>{`CMS sam optimira kvalitetu slike tako da je preporučeno da se `}<strong parentName="p">{`ne kompresuje slika`}</strong>{` prije uploada.`}</p>
    <p><img alt="alt text" src={require("./assets/strapi-doc-1.jpeg")} /></p>
    <p><img alt="alt text" src={require("./assets/strapi-doc-2.jpeg")} /></p>
    <p>{`Po istom principu kao i osnovni tekst, nakon ažuriranja dokumenata i slika potrebno je kliknuti zeleno dugme `}<strong parentName="p">{`Save`}</strong>{`.`}</p>
    <p>{`Također, moguće je uraditi crop slike klikom na olovku i postavljanjem novih dimenzija.`}</p>
    <h3 {...{
      "id": "ažuriranje-lista-npr-kontakt-podaci"
    }}>{`Ažuriranje lista (npr. kontakt podaci)`}</h3>
    <p>{`Radi po istom principu kao i ažuriranje ostalog tekstualnog sadržaja. Klikom na `}<strong parentName="p">{`Add new entry`}</strong>{` dugme dodajete novu stavku na listu.`}</p>
    <p>{`U slučaju kontakt podataka, možete dodati proizvoljan broj različitih kontakt kanala koji će se prikazati u kontakt formi na stranici.`}</p>
    <h2 {...{
      "id": "generalni-registri"
    }}>{`Generalni registri`}</h2>
    <p>{`Većina šifrarnika je direktno integrisana sa web zahtjevom. Možete odrediti da li neki podatak treba da se vidi u web zahtjevu ili ne tako što ćete odabrati `}<strong parentName="p">{`Web Zahtjev`}</strong>{` `}<strong parentName="p">{`DA`}</strong>{` ili `}<strong parentName="p">{`NE`}</strong>{` sa padajućeg menija u generalnim registrima iMikro aplikacije.`}</p>
    <p><img alt="alt text" src={require("./assets/sifrarnici.jpg")} /></p>
    <p>{`Ove izmjene se direktno i trenutno odraze na admin aplikaciju, jer ona vuče vrijednosti iz baze.`}</p>
    <p>{`U slučaju web zahtjeva koji koriste klijenti za popunjavanje forme `}<strong parentName="p">{`potrebna je dodatna akcija`}</strong>{` kroz CMS da bi se pokrenuo rebuild i stanje ažuriralo.`}</p>
    <p><img alt="alt text" src={require("./assets/strapi-webhook.jpeg")} /></p>
    <h2 {...{
      "id": "web-zahtjev-forma"
    }}>{`Web zahtjev forma`}</h2>
    <p>{`Polja, raspored polja po koracima, dozvoljeni iznose i druga polja koja su usko uključena u proces prepisa i integracije zahtjeva u iMikro trenutno se ne mogu podešavati od strane korisnika.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      